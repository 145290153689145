class Menue {
    constructor(menueButtonId) {
        this.menueAktiv = false;
        const isStartseite = window.location.pathname === '/';
        // this.menue = isStartseite ? document.getElementById('menue__button') : document.getElementById('header');
        this.menueButton = document.getElementById(menueButtonId);
        this.y = 0;

        this.menueToggle = this.menueToggle.bind(this);
        this.menueBodyClick = this.menueBodyClick.bind(this);
        this.menueSeiteHover = this.menueSeiteHover.bind(this);

        this.menueButton.addEventListener('click', (event) => {
            event.preventDefault();
            event.stopPropagation();
            this.menueToggle();
        });
        const menue = document.getElementById('menue');
        menue.addEventListener('click', (event) => {
            if (this.menueAktiv == true) {
                const { target } = event;
                if (target.matches('.menue__link')) {
                    this.menueToggle();
                }
            }
        });
    }

    menueToggle() {
        // Menü deaktivieren
        if (this.menueAktiv == true) {
            this.menueAktiv = false;
            document.body.removeEventListener('click', this.menueBodyClick);
            document.body.setAttribute('data-menue-aktiv', this.menueAktiv);
            this.menueSeiteHover(false);
            // this.menue.style.marginRight = '';
            document.body.style.paddingRight = '';
            document.body.style.top = '';
            window.scrollTo(0, this.y);
        // Menü aktivieren
        } else {
            this.menueAktiv = true;
            document.body.addEventListener('click', this.menueBodyClick);
            var scrollbar = window.innerWidth - document.documentElement.clientWidth;
            this.y = window.scrollY;
            document.body.setAttribute('data-menue-aktiv', this.menueAktiv);
            // var marginOriginal = parseFloat(window.getComputedStyle(this.menue).marginRight);
            // this.menue.style.marginRight = marginOriginal + scrollbar + 'px';
            document.body.style.paddingRight = scrollbar + 'px';
            document.body.style.top = -this.y + 'px';
        }
        // Event erstellen
        let event = new CustomEvent('eventMenuestatus', {
            detail: {
                menueStatus: this.menueAktiv
            }
        });
        // dispatch the event
        document.dispatchEvent(event);
    }

    menueBodyClick(event) {
        if (this.menueAktiv == true) {
            if (!event.target.closest('.header__menue')) {
                this.menueToggle();
            }
        }
    }

    menueSeiteHover(event) {
        if (event.type == 'mouseenter') {
            document.body.setAttribute('data-seite-hover', 'true');
        } else {
            document.body.setAttribute('data-seite-hover', 'false');
        }
    }
}

var menueObjekt;
document.addEventListener('DOMContentLoaded', function () {
    menueObjekt = new Menue('header__button--menue');
});