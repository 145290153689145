class iosBarsBg {
    constructor() {
        addEventListener("DOMContentLoaded", (event) => {
            this.init();
        });
    }

    init(params) {
        const sections = gsap.utils.toArray('[data-hintergrundfarbe]');
        const metaTheme = document.querySelector('meta[name="theme-color"]');
        const body = document.body;
        let themeArray = [];
        let bgArray = [];
        
        sections.forEach((section, i) => {
            const farbe = section.getAttribute('data-hintergrundfarbe');
            
            /**
             * Statusbar (oben) einfärben: meta theme
             */
            themeArray[i] = gsap.timeline({
                scrollTrigger: {
                    trigger: section,
                    start: 'top top',
                    end: 'bottom top',
                    // Dem GSAP Objekt lassen sich eigene Variablen hinzufügen
                    hintergrundfarbe: farbe,
                    onEnter: () => metaTheme.setAttribute('content', farbe),
                    // onEnter: () => console.log('theme', 'onEnter', farbe),
                    onEnterBack: (self) => metaTheme.setAttribute('content', self.vars.hintergrundfarbe),
                    // onEnterBack: (self) => console.log('theme', 'onLeaveBack', self.vars.hintergrundfarbe),
                    // markers: true,
                }
            });
            
            /**
             * Navigationbar (unten) einfärben: body background-color setzen
             */
            bgArray[i] = gsap.timeline({
                scrollTrigger: {
                    trigger: section,
                    start: 'top bottom',
                    end: 'bottom bottom',
                    hintergrundfarbe: farbe,
                    onEnter: () => body.style.setProperty('background-color', farbe),
                    // onEnter: () => console.log('bg', 'onEnter', farbe),
                    onEnterBack: (self) => body.style.setProperty('background-color', self.vars.hintergrundfarbe),
                    // onEnterBack: (self) => console.log('bg', 'onLeaveBack', self.vars.hintergrundfarbe),
                    // markers: true,
                }
            });
        });
    }
}

const iosBarsBgObject = new iosBarsBg();